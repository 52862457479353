// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-contact-template-tsx": () => import("./../src/templates/ContactTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../src/templates/AboutTemplate.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-division-index-template-tsx": () => import("./../src/templates/DivisionIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-division-index-template-tsx" */),
  "component---src-templates-solutions-template-tsx": () => import("./../src/templates/SolutionsTemplate.tsx" /* webpackChunkName: "component---src-templates-solutions-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../src/templates/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-energy-e-scooter-tsx": () => import("./../src/pages/energy/e-scooter.tsx" /* webpackChunkName: "component---src-pages-energy-e-scooter-tsx" */),
  "component---src-pages-energy-energy-storage-tsx": () => import("./../src/pages/energy/energy-storage.tsx" /* webpackChunkName: "component---src-pages-energy-energy-storage-tsx" */),
  "component---src-pages-energy-powerwall-tsx": () => import("./../src/pages/energy/powerwall.tsx" /* webpackChunkName: "component---src-pages-energy-powerwall-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

